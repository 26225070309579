import React from "react";
import { Modal, Button } from "react-bootstrap";

const HubDetailModal = ({ show, onHide, hub }) => {
  if (!hub) return null;
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Hub Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Hub Name:</strong> {hub.name}
        </p>
        <p>
          <strong>Address:</strong> {hub.address}
        </p>
        <p>
          <strong>Country:</strong> {hub.country}
        </p>
        <p>
          <strong>State:</strong> {hub.state}
        </p>
        <p>
          <strong>City:</strong> {hub.city}
        </p>

        <p>
          <strong>Coordinates:</strong>{" "}
          {hub.coordinates ? (
            <>
              <span>
                <strong>Latitude:</strong> {hub.coordinates.latitude}
              </span>
              <span>, </span>
              <span>
                <strong>Longitude:</strong> {hub.coordinates.longitude}
              </span>
            </>
          ) : (
            <span>N/A</span>
          )}
        </p>
        <p>
          <strong>Area:</strong> {hub.area}
        </p>
        <p>
          <strong>Count:</strong> {hub.count}
        </p>

        <p>
          <strong>On-Boarding Date : </strong> {formatDate(hub.onBoardingDate)}
        </p>
        <p>
          <strong>Status:</strong> {hub.status}
        </p>

        <p>
          <strong>Created By:</strong>{" "}
          {hub.createdBy && (
            <>
              <span>
                <strong>Name:</strong> {hub.createdBy.user_email}
              </span>
              <span>, </span>
              <span>
                <strong>Role:</strong> {hub.createdBy.user_role}
              </span>
            </>
          )}
        </p>
        <p>
          <strong>Created Date:</strong>{" "}
          {hub.createdAt && <>{new Date(hub.createdAt).toLocaleDateString()}</>}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HubDetailModal;
