import React, { useEffect, useState } from "react";
import { useSocket } from "../../context/SocketContext.js";
import Layouts from "../../layouts/layouts";

const VehicleTracking = () => {
  const socket = useSocket();
  const [vehicles, setVehicles] = useState([]);
  const [assignedVehicles, setAssignedVehicles] = useState([]);
  const [errorVehicles, setErrorVehicles] = useState([]);
  useEffect(() => {
    if (socket) {
      socket.on("vehicle-locations", (data) => {
        setVehicles(data.allVehicles || []);
        setAssignedVehicles(data.atAssignedLocation || []);
        setErrorVehicles(data.vehiclesWithError || []);
      });
      socket.on("error", (error) => {
        console.error("Error from server:", error.message);
      });
    }
    return () => {
      if (socket) {
        socket.off("vehicle-locations");
        socket.off("error");
      }
    };
  }, [socket]);
  return (
    <>
      <Layouts />
      <div>
        <h1>Vehicle Tracking</h1>
        <div>
          <h2>All Vehicles</h2>
          {vehicles.length > 0 ? (
            <ul>
              {vehicles.map((vehicle, index) => (
                <li key={index}>
                  {vehicle.numberplate} - Distance:{" "}
                  {vehicle.distanceFromAssigned}
                </li>
              ))}
            </ul>
          ) : (
            <p>No vehicles found.</p>
          )}
        </div>
        <div>
          <h2>Vehicles at Assigned Location</h2>
          {assignedVehicles.length > 0 ? (
            <ul>
              {assignedVehicles.map((vehicle, index) => (
                <li key={index}>
                  {vehicle.numberplate} - Distance:{" "}
                  {vehicle.distanceFromAssigned}
                </li>
              ))}
            </ul>
          ) : (
            <p>No vehicles at assigned locations.</p>
          )}
        </div>
        <div>
          <h2>Vehicles with Errors</h2>
          {errorVehicles.length > 0 ? (
            <ul>
              {errorVehicles.map((vehicle, index) => (
                <li key={index}>{vehicle}</li>
              ))}
            </ul>
          ) : (
            <p>No vehicles with errors.</p>
          )}
        </div>
      </div>
    </>
  );
};
export default VehicleTracking;
