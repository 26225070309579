import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateLease,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);

      const selectedVehicle = select_data.id_vehicle
        .map((item) => {
          const vehicleObj = vehicles.find(
            (vehicle) => vehicle._id === item._id
          );
          return vehicleObj
            ? { value: vehicleObj._id, label: vehicleObj.numberplate }
            : null;
        })
        .filter(Boolean); // Remove any null values if vehicle isn't found

      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_vehicle: selectedVehicle, // Store as [{ value, label }]
      }));
    }
  }, [select_data, vehicles]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setVehicles(response.data.vehicles);
      } catch (error) {
        console.error("Error fetching vehicles:", error);
        setVehicles([]);
      }
    };
    fetchVehicles();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "id_vehicle") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_vehicle: { ...prevData.id_vehicle, [name]: value },
      }));
    } else {
      setUpdate_modal_data({ ...update_modal_data, [name]: value });
    }
  };

  const handleVehicleChange = (selectedOptions) => {
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_vehicle: selectedOptions || [], // Keep the structure [{ value, label }]
    }));
  };

  const validateFields = () => {
    const newErrors = {};
    if (!update_modal_data.id_vehicle)
      newErrors.id_vehicle = "Vehicle is required";
    if (!update_modal_data.leasing_partner)
      newErrors.leasing_partner = "Leasing Partner is required";
    if (!update_modal_data.status) newErrors.status = "Status is required";
    if (!update_modal_data.onboardingDate)
      newErrors.onboardingDate = "On Boarding Date is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    if (!validateFields()) {
      Swal.fire("Please fill in all required fields", "", "error");
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to take this action?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { _id } = update_modal_data;
          if (!_id) {
            throw new Error("ID is not defined");
          }

          // Create a new FormData object
          const formData = new FormData();
          if (Array.isArray(update_modal_data.id_vehicle)) {
            update_modal_data.id_vehicle.forEach((vehicle, index) => {
              formData.append(`id_vehicle[${index}]`, vehicle.value || vehicle);
            });
          }
          formData.append("leasing_partner", update_modal_data.leasing_partner);
          formData.append("onboardingDate", update_modal_data.onboardingDate);
          formData.append("status", update_modal_data.status);
          formData.append("lastDate", update_modal_data.lastDate || ""); // Pass "" if lastDate is empty or undefined
          formData.append("leasing_amount", update_modal_data.leasing_amount);
          formData.append("comments", update_modal_data.comments);

          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/v3/lease/${_id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (res.data.success) {
            Swal.fire("Update success", "", "success");
            onUpdateLease(update_modal_data);
            setRen(!ren);
          }
        } catch (error) {
          Swal.fire("Update failed", "", "error");
          console.error("Error updating lease:", error);
        }
        set_update_data_modal_Show(false);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Lease Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Vehicle *</Form.Label>
              <Select
                name="id_vehicle"
                defaultValue={update_modal_data.id_vehicle || []} // Directly use the array
                options={vehicles.map((vehicle) => ({
                  value: vehicle._id,
                  label: vehicle.numberplate,
                }))}
                isMulti
                onChange={handleVehicleChange}
                className={`basic-multi-select ${
                  errors.id_vehicle ? "is-invalid" : ""
                }`}
                classNamePrefix="select"
              />
              {errors.id_vehicle && (
                <Form.Control.Feedback type="invalid">
                  {errors.id_vehicle}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Leasing Partner *</Form.Label>
              <Form.Control
                type="text"
                name="leasing_partner"
                value={update_modal_data.leasing_partner || ""}
                onChange={handleChange}
                isInvalid={!!errors.leasing_partner}
              />
              <Form.Control.Feedback type="invalid">
                {errors.leasing_partner}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Leasing Amount *</Form.Label>
              <input
                type="number"
                className="form-control"
                name="leasing_amount"
                value={update_modal_data.leasing_amount || ""}
                onChange={handleChange}
              />
              {errors.leasing_amount && (
                <p className="text-danger">{errors.leasing_amount}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Status *</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={update_modal_data.status || ""}
                onChange={handleChange}
                isInvalid={!!errors.status}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>OnBoard Date *</Form.Label>
              <Form.Control
                type="date"
                name="onboardingDate"
                value={update_modal_data.onboardingDate || ""}
                onChange={handleChange}
              />
              {errors.onboardingDate && (
                <small className="text-danger">{errors.onboardingDate}</small>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>End Date </Form.Label>
              <Form.Control
                type="date"
                name="lastDate"
                value={update_modal_data.lastDate || ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Comments *</Form.Label>
              <Form.Control
                type="text"
                name="comments"
                value={update_modal_data.comments || ""}
                onChange={handleChange}
                isInvalid={!!errors.comments}
              />
              <Form.Control.Feedback type="invalid">
                {errors.comments}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update Lease
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
