import React from "react";
import { Modal, Button } from "react-bootstrap";

const LeaseDetailModal = ({ show, onHide, lease }) => {
  if (!lease) return null;
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Lease Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Vehicle Numberplate:</strong>
          {Array.isArray(lease.id_vehicle) && lease.id_vehicle.length > 0 ? (
            lease.id_vehicle.map((vehicle, index) => (
              <div key={vehicle._id}>
                <span>
                  Vehicle {index + 1} - {vehicle.numberplate}
                </span>
                {/* {client.phonenumber && <span>, {client.phonenumber}</span>} */}
              </div>
            ))
          ) : (
            <span> N/A</span>
          )}
        </p>
        <p>
          <strong>Leasing Partner : </strong> {lease.leasing_partner}
        </p>
        <p>
          <strong>Leasing Amount : </strong> {lease.leasing_amount}
        </p>
        <p>
          <strong>On-Boarding Date : </strong>{" "}
          {formatDate(lease.onboardingDate)}
        </p>
        <p>
          <strong>Last Date : </strong> {formatDate(lease.lastDate)}
        </p>
        <p>
          <strong>Status : </strong>
          {lease.status}
        </p>
        <p>
          <strong>Comments : </strong> {lease.comments}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LeaseDetailModal;
