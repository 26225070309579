import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import FilterModal from "../Filter Modal/FilterModal";

export default function DataTable_Component({
  apidata,
  columns,
  searchBy,
  setSalary,
}) {
  const [dataFilter, setDataFilter] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [clientFilter, setClientFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  useEffect(() => {
    let filtered = apidata;
    if (dataFilter) {
      filtered = filtered.filter((item) => {
        if (searchBy === "name") {
          return (
            item.id_user &&
            item.id_user.name &&
            item.id_user.name.toLowerCase().startsWith(dataFilter.toLowerCase())
          );
        }
        return false;
      });
    }
    if (clientFilter) {
      filtered = filtered.filter((item) =>
        item.id_user?.id_client?.some((client) =>
          client.name.toLowerCase().includes(clientFilter.toLowerCase())
        )
      );
    }

    if (statusFilter) {
      filtered = filtered.filter(
        (item) =>
          item.id_user?.status &&
          item.id_user.status.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    setFilteredData(filtered);
    const totalSalarySum = filtered.reduce(
      (sum, item) => sum + (item.total_salary || 0), // Sum salaries from filtered data
      0
    );

    setSalary(totalSalarySum); // Update salary in parent component
  }, [dataFilter, apidata, clientFilter, setSalary, statusFilter, searchBy]);

  const applyFilter = () => {
    setShowFilterModal(false);
  };

  const removeFilter = () => {
    setStatusFilter("");

    setClientFilter("");
    setShowFilterModal(false);
  };

  return (
    <div className="table-responsive">
      <div className="row justify-content-between mb-2">
        <div className="col-md-3 mb-2">
          <input
            type="text"
            placeholder={`Search by ${searchBy}`}
            className="form-control"
            value={dataFilter}
            onChange={(e) => setDataFilter(e.target.value)}
          />
        </div>
        <div className="col-md-9 mb-2">
          <button
            className="btn btn-primary"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>
        </div>
      </div>

      {filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          highlightOnHover
          responsive
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      ) : (
        <div className="row justify-content-start">
          <div className="col-12">
            <p>No results found.</p>
          </div>
        </div>
      )}
      <FilterModal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        apidata={apidata}
        applyFilter={applyFilter}
        removeFilter={removeFilter}
        applyFilters={() => setShowFilterModal(false)}
        removeFilters={() => {
          setClientFilter("");
          setShowFilterModal(false);
        }}
        clientFilter={clientFilter}
        setClientFilter={setClientFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
      />
    </div>
  );
}
