import React from "react";
import { Modal, Button } from "react-bootstrap";

const DefectDetailModal = ({ show, onHide, defects }) => {
  if (!defects) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Vehicle Defect Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Defect Name:</strong> {defects.defectName}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DefectDetailModal;
