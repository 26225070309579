import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

export default function FilterModal({
  apidata,
  show,
  onHide,
  applyFilters,
  removeFilters,
  fromDate,
  setFromDate,
  clientFilter,
  setClientFilter,
}) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="fromDate">
            <Form.Label>From Date</Form.Label>
            <Form.Control
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="clientFilter">
            <Form.Label>Client</Form.Label>
            <Form.Control
              as="select"
              value={clientFilter}
              onChange={(e) => setClientFilter(e.target.value)}
            >
              <option value="">All Clients</option>

              {apidata &&
                Array.from(new Set(apidata.map((item) => item.id_client?.name)))
                  .filter(Boolean)
                  .map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={removeFilters}>
          Remove Filters
        </Button>
        <Button variant="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
