import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import { Country, State, City } from "country-state-city";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import Layouts from "../../layouts/layouts";
import Select from "react-select";

export default function Create_lease() {
  const title = "Add Lease";
  const [formData, setFormData] = useState({
    id_vehicle: [],
    leasing_partner: "",
    leasing_amount: "",
    status: "Active",
    onboardingDate: "",
    lastDate: "",
    comments: "",
  });

  const [autocomplete, setAutocomplete] = useState(null);
  const [errors, setErrors] = useState({});
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [vehicles, setVehicles] = useState([]); // Store fetched vehicles

  const navigate = useNavigate();

  const brad = [{ name: "Home" }, { name: title }];

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200 && response.data.vehicles) {
          setVehicles(response.data.vehicles); // Update vehicles state
        } else {
          console.error("No vehicles found in the response.");
          setVehicles([]);
        }
      } catch (error) {
        console.error("Error fetching vehicles:", error);
        setVehicles([]); // Handle errors
      }
    };

    fetchVehicles();
  }, []);

  // const handleVehicleChange = (selectedOptions) => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     id_vehicle: selectedOptions
  //       ? selectedOptions.map((option) => option.value)
  //       : [],
  //   }));
  // };
  const handleVehicleChange = (selectedOptions) => {
    const vehicleIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    console.log("Selected Vehicles:", vehicleIds);

    setFormData((prevFormData) => ({
      ...prevFormData,
      id_vehicle: vehicleIds,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.id_vehicle) newErrors.id_vehicle = "Vehicle is required.";
    if (!formData.onboardingDate)
      newErrors.from = "OnBoarding date is required.";
    if (!formData.leasing_partner)
      newErrors.leasing_partner = "Leasing Partner is required.";
    if (!formData.leasing_amount)
      newErrors.leasing_amount = "Leasing Amount is required.";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    const data = new FormData();
    if (Array.isArray(formData.id_vehicle)) {
      formData.id_vehicle.forEach((vehicleId) => {
        data.append("id_vehicle[]", vehicleId);
      });
    }

    if (formData.leasing_partner) {
      data.append("leasing_partner", formData.leasing_partner);
    }
    data.append("status", formData.status);
    data.append("onboardingDate", formData.onboardingDate);
    data.append("lastDate", formData.lastDate);
    data.append("comments", formData.comments);
    data.append("leasing_amount", formData.leasing_amount);

    for (let [key, value] of data.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v3/lease/new`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response data:", response.data);

      if (response.status === 201) {
        navigate("/lease");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      //   console.error("Error:", error);

      console.error("Error:", error.response ? error.response.data : error);
    }
    console.log("FormData before submission:", formData);
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="id_vehicle">
                  <b>Vehicle* </b>
                </label>
                <Select
                  name="id_vehicle"
                  options={vehicles.map((vehicle) => ({
                    value: vehicle._id,
                    label: `${vehicle.numberplate}`,
                  }))}
                  isMulti
                  onChange={handleVehicleChange}
                  className={`basic-multi-select ${
                    errors.id_vehicle ? "is-invalid" : ""
                  }`}
                  classNamePrefix="select"
                />
                {errors.id_vehicle && (
                  <p className="text-danger">{errors.id_vehicle}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="leasing_partner">
                  <b>Leasing Partner *</b>
                </label>
                <input
                  type="text"
                  name="leasing_partner"
                  placeholder="leasing_partner"
                  className="form-control"
                  value={formData.leasing_partner}
                  onChange={onChangeHandler}
                />
                {errors.leasing_partner && (
                  <small className="text-danger">
                    {errors.leasing_partner}
                  </small>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="leasing_amount">
                  <b>Leasing Amount </b>
                </label>
                <input
                  type="number"
                  name="leasing_amount"
                  placeholder="leasing_amount of Vehicle"
                  className="form-control"
                  value={formData.leasing_amount}
                  onChange={onChangeHandler}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="to">
                  <b>On-Boarding Date *</b>
                </label>
                <input
                  type="date"
                  name="onboardingDate"
                  className="form-control"
                  value={formData.onboardingDate}
                  onChange={onChangeHandler}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="to">
                  <b>Last Date </b>
                </label>
                <input
                  type="date"
                  name="lastDate"
                  className="form-control"
                  value={formData.lastDate}
                  onChange={onChangeHandler}
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="status">
                  <b>Status *</b>
                </label>
                <select
                  name="status"
                  className="form-control"
                  value={formData.status}
                  onChange={onChangeHandler}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                {errors.status && (
                  <p className="text-danger">{errors.status}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="comments">
                  <b>Comments *</b>
                </label>
                <input
                  type="text"
                  name="comments"
                  placeholder="comments"
                  className="form-control"
                  value={formData.comments}
                  onChange={onChangeHandler}
                />
              </div>

              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Lease
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
