import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Layouts from "../../layouts/layouts";

export default function Create_VehicleVerification() {
  const title = "Add Defect Type";
  const [formData, setFormData] = useState({
    defectName: "",
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const brad = [{ name: "Home" }, { name: title }];

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onLoad = (autoComp) => {
    setAutocomplete(autoComp);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.defectName) newErrors.name = "Defect Name is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const formDataToSubmit = {
        defectName: formData.defectName,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v3/vehicle-defect/new`,
        formDataToSubmit,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        navigate("/vehicleverification");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="defectName">
                  <b> Defect Name *</b>
                </label>
                <input
                  type="text"
                  name="defectName"
                  placeholder="Defect Name"
                  className="form-control"
                  onChange={onChangeHandler}
                  value={formData.defectName}
                />
                {errors.name && (
                  <small className="text-danger">{errors.defectName}</small>
                )}
              </div>

              <div className="col-md-6 mt-3"></div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Vehicle Verification
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
