import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { State } from "country-state-city";
import axios from "axios";

export default function FilterModal({
  show,
  onHide,
  applyFilters,
  removeFilters,
  clientFilter,
  setClientFilter,
  statusFilter,
  setStatusFilter,
  apidata,
}) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {" "}
          <Form.Group controlId="clientFilter">
            <Form.Label>Client</Form.Label>
            <Form.Control
              as="select"
              value={clientFilter}
              onChange={(e) => setClientFilter(e.target.value)}
            >
              <option value="">All Clients</option>

              {apidata &&
                apidata
                  .map((item) =>
                    item.id_user?.id_client?.map((client) => client.name)
                  )
                  .flat() // Flatten the array of arrays to get a single array of client names
                  .filter((name, index, self) => self.indexOf(name) === index) // Remove duplicates
                  .filter(Boolean) // Remove any null or undefined values
                  .map((clientName, index) => (
                    <option key={index} value={clientName}>
                      {clientName}
                    </option>
                  ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="statusFilter">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="">All Status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={removeFilters}>
          Remove Filters
        </Button>
        <Button variant="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
