import React from "react";
import { Modal, Button } from "react-bootstrap";

const VehicleDetailModal = ({ show, onHide, vehicle }) => {
  if (!vehicle) return null;
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Vehicle Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Number Plate:</strong> {vehicle.numberplate}
        </p>
        <p>
          <strong>GPS Status:</strong> {vehicle.gpsstatus ? "True" : "False"}
        </p>
        <p>
          <strong>Vehicle Type:</strong>{" "}
          {vehicle.id_type ? vehicle.id_type.vehiclename : "N/A"}
        </p>
        <p>
          <strong>Vehicle Company:</strong>{" "}
          {vehicle.id_type ? vehicle.id_type.company : "N/A"}
        </p>
        <p>
          <strong>Wheeler Type:</strong>{" "}
          {vehicle.id_type ? vehicle.id_type.vehicle_type : "N/A"}
        </p>
        {/* <p>
          <strong>Vehicle Category:</strong>{" "}
          {vehicle.vehicleCategory ? vehicle.vehicleCategory : "N/A"}
        </p> */}
        <p>
          <strong>Driver Name: </strong>
          {vehicle.id_driver && vehicle.id_driver.length > 0 ? (
            vehicle.id_driver.map((driver, index) => (
              <div key={driver._id}>
                <span>
                  Driver {index + 1} - {driver.id_user?.name}
                </span>
                {driver.phonenumber && <span>, {driver.phonenumber}</span>}
              </div>
            ))
          ) : (
            <span> N/A</span>
          )}
        </p>
        <p>
          <strong>Hub Name:</strong>
          {Array.isArray(vehicle.id_hub) && vehicle.id_hub.length > 0 ? (
            vehicle.id_hub.map((hub, index) => (
              <div key={hub._id}>
                <span>
                  Hubs {index + 1} - {hub.name}
                </span>
              </div>
            ))
          ) : (
            <span> N/A</span>
          )}
        </p>
        <p>
          <strong>Model:</strong> {vehicle.model}
        </p>
        <p>
          <strong>Year:</strong> {vehicle.year}
        </p>
        <p>
          <strong>Color:</strong> {vehicle.color}
        </p>
        <p>
          <strong>Location:</strong> {vehicle.assignedlocation}
        </p>
        <p>
          <strong>Coordinates:</strong>{" "}
          {vehicle.coordinates ? (
            <>
              <span>
                <strong>Latitude:</strong> {vehicle.coordinates.latitude}
              </span>
              <span>, </span>
              <span>
                <strong>Longitude:</strong> {vehicle.coordinates.longitude}
              </span>
            </>
          ) : (
            <span>N/A</span>
          )}
        </p>

        <p>
          <strong>Vendor Name:</strong>{" "}
          {vehicle.id_vendor ? vehicle.id_vendor?.id_user?.name : "N/A"}
        </p>
        <p>
          <strong>Total KM:</strong> {vehicle.totalkm}
        </p>
        <p>
          <strong>Capacity:</strong> {vehicle.capacity}
        </p>
        <p>
          <strong>Lease:</strong> {vehicle.lease}
        </p>
        <p>
          <strong>Length:</strong> {vehicle.length}
        </p>
        <p>
          <strong>Status:</strong> {vehicle.status}
        </p>
        <p>
          <strong>On-Boarding Date : </strong>{" "}
          {formatDate(vehicle.onBoardingDate)}
        </p>
        <p>
          <strong>Comments:</strong> {vehicle.comments}
        </p>
        <p>
          <strong>Created By:</strong>{" "}
          {vehicle.createdBy && (
            <>
              <span>
                <strong>Name:</strong> {vehicle.createdBy.user_email}
              </span>
              <span>, </span>
              <span>
                <strong>Role:</strong> {vehicle.createdBy.user_role}
              </span>
            </>
          )}
        </p>
        <p>
          <strong>Created Date:</strong>{" "}
          {vehicle.createdAt && (
            <>{new Date(vehicle.createdAt).toLocaleDateString()}</>
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VehicleDetailModal;
