import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/vehiclecondition/Data Table";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Add_data_modal from "../../components/compliance/update_data_modal";
import ComplianceDetailModal from "../../components/compliance/Compliance Details";
import Layouts from "../../layouts/layouts";
import * as XLSX from "xlsx"; // Import XLSX library for Excel export
import {
  faEye,
  faPenToSquare,
  faTrash,
  faEnvelope,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Switch from "react-switch";

export default function Compliance() {
  const title = "Vehicle Audit Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [selectVal_details, setSelectVal_details] = useState([]);
  const [details_modal_show, set_details_modal_Show] = useState(false);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [update_modal_data, setUpdate_modal_data] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showComplianceDetailModal, setShowComplianceDetailModal] =
    useState(false);
  const [selectedCompliance, setSelectedCompliance] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const columns = [
    {
      name: <b>Vehicle Number</b>,
      selector: (row) => (row.id_vehicle ? row.id_vehicle.numberplate : "N/A"),
      sortable: true,
    },
    {
      name: <b>Client </b>,
      selector: (row) => (row.id_client ? row.id_client.name : "N/A"),
      sortable: true,
    },
    {
      name: <b>Audit Date</b>,
      selector: (row) => formatDate(row.createdAt),
      sortable: true,
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };
  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v3/vehicle-verifications`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (Array.isArray(response.data.verifications)) {
        const data = response.data.verifications;
        setApiState(data);

        // Extract unique defect names
        const defectNames = new Set();
        data.forEach((item) => {
          item.vDefect.forEach((defect) => {
            defectNames.add(defect.defectName);
          });
        });

        // Create dynamic columns
        const dynamicColumns = [...defectNames].map((defect) => ({
          name: <b>{defect}</b>,
          selector: (row) =>
            row.vDefect.some((d) => d.defectName === defect) ? "✅" : "❌",
          sortable: true,
        }));

        // Set columns including predefined ones
        setApiCol([
          {
            name: <b>Vehicle Number</b>,
            selector: (row) =>
              row.id_vehicle ? row.id_vehicle.numberplate : "N/A",
            sortable: true,
          },
          {
            name: <b>Audit Date</b>,
            selector: (row) => formatDate(row.createdAt),
            sortable: true,
          },
          {
            name: <b>Clients</b>,
            selector: (row) => {
              if (Array.isArray(row.id_client)) {
                return row.id_client.map((client) => client.name).join(", ");
              } else {
                return row.id_client && row.id_client.name
                  ? row.id_client.name
                  : "N/A";
              }
            },
            sortable: true,
          },

          {
            name: <b>Created By </b>,
            selector: (row) => (row.createdBy ? row.createdBy.name : "N/A"),
            sortable: true,
          },
          ...dynamicColumns,
          {
            name: <b>Additional Comments</b>,
            selector: (row) => row.comment,
            sortable: true,
          },
        ]);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
      />
      <ComplianceDetailModal
        show={showComplianceDetailModal}
        onHide={() => setShowComplianceDetailModal(false)}
        compliance={selectedCompliance}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
              </div>

              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="numberplate"
                    title_btn={true}
                    apidata={apistate}
                    // columns={apicol}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
