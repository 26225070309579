import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/lease/Data Table/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";

import Layouts from "../../layouts/layouts";
import Add_data_modal from "../../components/lease/update_data_modal";
import LeaseDetailModal from "../../components/lease/Lease Details";

export default function Lease() {
  const navigate = useNavigate();
  const title = "Lease Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [overallLeasing, setOverallLeasing] = useState(0);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showLeaseDetailModal, setShowLeaseDetailModal] = useState(false);
  const [selectedLease, setSelectedLease] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const columns = [
    {
      name: <b>Vehicles</b>,
      cell: (row) => {
        const vehicles =
          (Array.isArray(row.id_vehicle) &&
            row.id_vehicle.map((vehicle) => vehicle.numberplate)) ||
          [];

        return (
          <div style={{ position: "relative", zIndex: 1 }}>
            {vehicles.length > 0 ? (
              vehicles.map((numberplate, index) => (
                <p key={index} style={{ margin: "0" }}>
                  {numberplate}
                </p>
              ))
            ) : (
              <p style={{ margin: "0" }}>-</p>
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <b>Vehicles Count</b>,
      selector: (row) =>
        Array.isArray(row.id_vehicle) ? row.id_vehicle.length : 0, // Dynamic count based on vehicle array
      sortable: true,
      cell: (row) => (
        <button
          style={{
            backgroundColor: "green",
            color: "white",
            border: "none",
            borderRadius: "50%", // Makes the button circular
            width: "32px", // Set width to match height
            height: "30px", // Set height to define the circle size
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          {Array.isArray(row.id_vehicle) ? row.id_vehicle.length : 0}{" "}
          {/* Dynamic Count */}
        </button>
      ),
    },

    {
      name: <b>Vehicle Company</b>,
      selector: (row) =>
        row.id_vehicle.length > 0 && row.id_vehicle[0].id_type
          ? row.id_vehicle[0].id_type.company
          : "N/A",
      sortable: true,
    },

    {
      name: <b>Leasing Partner</b>,
      selector: (row) => row.leasing_partner,
      sortable: true,
    },
    {
      name: <b>Leasing Amount</b>,
      selector: (row) => row.leasing_amount,
      sortable: true,
    },
    {
      name: <b>OnBoard Date</b>,
      selector: (row) => formatDate(row.onboardingDate),
      sortable: true,
    },
    {
      name: <b>Last Date</b>,
      selector: (row) => formatDate(row.lastDate),
      sortable: true,
    },
    {
      name: <b>Total Leasing</b>, // New column header
      cell: (row) => {
        const vehicles =
          (Array.isArray(row.id_vehicle) &&
            row.id_vehicle.map((vehicle) => vehicle.numberplate)) ||
          [];
        const totalLeasing = vehicles.length * row.leasing_amount || 0;

        return (
          <div style={{ position: "relative", zIndex: 1 }}>
            {totalLeasing > 0 ? totalLeasing : <p style={{ margin: "0" }}>-</p>}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <b>Comments</b>,
      selector: (row) => row.comments,
      sortable: true,
    },
    {
      name: <b>Status</b>,
      cell: (row) => {
        const status = row.status?.toLowerCase();
        return (
          <button
            onClick={() => handleShow(row)}
            className={`btn p-0 px-1 ${
              status === "active"
                ? "btn-success"
                : status === "inactive"
                ? "btn-danger"
                : ""
            }`}
          >
            {row.status || "Unknown"}
          </button>
        );
      },
    },

    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className="btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => viewLease(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className=" btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      ),
    },
  ];
  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const delete_row = (row) => {
    Swal.fire({
      icon: "warning",
      title: "You wont be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/api/v3/lease/${row._id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            Swal.fire("Deleted!", "Client has been deleted.", "success");
            fetchData(currentPage);
          })
          .catch((error) => {
            Swal.fire("Error!", "Client could not be deleted.", "error");
            console.error("Error deleting client:", error);
          });
      }
    });
  };

  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v3/leases`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (Array.isArray(response.data.leases)) {
        setApiState(response.data.leases);

        // Calculate total leasing by summing up all the total leasing values
        const totalLeasingSum = response.data.leases.reduce((sum, lease) => {
          const vehicles =
            (Array.isArray(lease.id_vehicle) &&
              lease.id_vehicle.map((vehicle) => vehicle.numberplate)) ||
            [];

          console.log(sum + vehicles.length * lease.leasing_amount);
          return sum + (vehicles.length * lease.leasing_amount || 0);
        }, 0);

        console.log("TotalLeasingSum", totalLeasingSum);
        // Set the overall leasing total
        setOverallLeasing(totalLeasingSum);
      } else {
        console.error("Unexpected data format from API", response.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  console.log(overallLeasing);

  // const fetchData = async (page) => {
  //   try {
  //     const token = localStorage.getItem("token");
  //     if (!token) {
  //       console.error("No token found");
  //       return;
  //     }
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/api/v3/leases`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (Array.isArray(response.data.leases)) {
  //       setApiState(response.data.leases);
  //     } else {
  //       console.error("Unexpected data format from API", response.data);
  //     }
  //     setApiCol(columns);
  //   } catch (error) {
  //     console.error("Error fetching data: ", error);
  //   }
  // };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const viewLease = (lease) => {
    setSelectedLease(lease);
    setShowLeaseDetailModal(true);
  };
  const updateLease = (updatedLease) => {
    setApiState((prev) =>
      prev.map((lease) =>
        lease._id === updatedLease._id ? updatedLease : lease
      )
    );
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateLease={updateLease}
      />
      <LeaseDetailModal
        show={showLeaseDetailModal}
        onHide={() => setShowLeaseDetailModal(false)}
        lease={selectedLease}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {" "}
                  <div className="d-flex align-items-center">
                    <button
                      className="me-4"
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        borderColor: "white",
                      }}
                    >
                      Overall Leasing : {overallLeasing}{" "}
                    </button>{" "}
                  </div>
                </div>
                <Link to="/create_lease">
                  <div className="btn btn-info btn-sm text-white">
                    Add Lease
                  </div>
                </Link>
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="numberplate"
                    title_btn={true}
                    title="Vehicle List"
                    apidata={apistate}
                    columns={apicol}
                    setOverallLeasing={setOverallLeasing}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
