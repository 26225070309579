import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import DataTable_Component from "../../components/attendanceconsolidated/Data Table/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import moment from "moment";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

import Layouts from "../../layouts/layouts";

export default function Attendance() {
  const navigate = useNavigate();
  const title = "Attendance Consolidated Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showHubDetailModal, setShowHubDetailModal] = useState(false);
  const [selectedHub, setSelectedHub] = useState(null);

  const fetchData = async (filterFromDate = "", filterToDate = "") => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const url = `${process.env.REACT_APP_BASE_URL}/api/v3/consolidate-attendance-between-dates-02`;
      const params = {};

      if (filterFromDate && filterToDate) {
        params.fromDate = filterFromDate;
        params.toDate = filterToDate;
      } else {
        const today = new Date();
        const firstDayOfPrevMonth = moment(today)
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        const lastDayOfPrevMonth = moment(today)
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");

        params.fromDate = firstDayOfPrevMonth;
        params.toDate = lastDayOfPrevMonth;
      }

      const response = await axios.get(url, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const apiResponse = response.data;

      if (apiResponse.success && apiResponse.data) {
        const attendanceData = apiResponse.data.attendance || [];
        const uniqueDatesSet = new Set();
        attendanceData.forEach((user) => {
          user.shifts.forEach((shift) => {
            const date = new Date(shift.check_in).toLocaleDateString();
            uniqueDatesSet.add(date);
          });
        });

        const uniqueDates = Array.from(uniqueDatesSet).sort(
          (a, b) => new Date(a) - new Date(b)
        );

        const dynamicColumns = uniqueDates.map((date) => ({
          name: <b>{moment(date).format("D MMM YYYY")}</b>,
          selector: (row) => {
            const shift = row.shifts.find(
              (shift) => new Date(shift.check_in).toLocaleDateString() === date
            );
            return shift ? (
              <FaCheckCircle color="green" size={20} />
            ) : (
              <FaTimesCircle color="red" size={20} />
            );
          },
          sortable: true,
        }));

        const staticColumns = [
          {
            name: <b>User Name</b>,
            selector: (row) => (row.userData ? row.userData.name : "-"),
            sortable: true,
            minWidth: "200px", // Increase width
          },
          {
            name: <b>Phone Number</b>,
            selector: (row) => (row.userData ? row.userData.phonenumber : "-"),
            sortable: true,
            minWidth: "180px", // Increase width
          },
          {
            name: <b>Hub Name</b>,
            selector: (row) =>
              row.currentHubs && row.currentHubs.length > 0
                ? row.currentHubs.map((hub) => hub.name).join(", ")
                : "-",
            sortable: true,
            minWidth: "250px", // Increase width
          },
        ];

        setApiCol([...staticColumns, ...dynamicColumns]);
        setApiState(attendanceData);
      } else {
        console.error("Unexpected data format from API", apiResponse);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  // const fetchData = async (filterFromDate = "", filterToDate = "") => {
  //   try {
  //     const token = localStorage.getItem("token");
  //     if (!token) {
  //       console.error("No token found");
  //       return;
  //     }

  //     const url = `${process.env.REACT_APP_BASE_URL}/api/v3/consolidate-attendance-between-dates-02`;
  //     const params = {};

  //     if (filterFromDate && filterToDate) {
  //       params.fromDate = filterFromDate;
  //       params.toDate = filterToDate;
  //     } else {
  //       const today = new Date();
  //       const firstDayOfPrevMonth = moment(today)
  //         .subtract(1, "months")
  //         .startOf("month")
  //         .format("YYYY-MM-DD");
  //       const lastDayOfPrevMonth = moment(today)
  //         .subtract(1, "months")
  //         .endOf("month")
  //         .format("YYYY-MM-DD");

  //       params.fromDate = firstDayOfPrevMonth;
  //       params.toDate = lastDayOfPrevMonth;
  //     }

  //     const response = await axios.get(url, {
  //       params,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     const apiResponse = response.data;

  //     if (apiResponse.success && apiResponse.data) {
  //       const attendanceData = apiResponse.data.attendance || [];
  //       const uniqueDatesSet = new Set();
  //       attendanceData.forEach((user) => {
  //         user.shifts.forEach((shift) => {
  //           const date = new Date(shift.check_in).toLocaleDateString();
  //           uniqueDatesSet.add(date);
  //         });
  //       });

  //       const uniqueDates = Array.from(uniqueDatesSet).sort(
  //         (a, b) => new Date(a) - new Date(b)
  //       );

  //       const dynamicColumns = uniqueDates.map((date) => ({
  //         name: <b>{moment(date).format("D MMM YYYY")}</b>,
  //         selector: (row) => {
  //           const shift = row.shifts.find(
  //             (shift) => new Date(shift.check_in).toLocaleDateString() === date
  //           );
  //           return shift ? new Date(shift.check_in).toLocaleTimeString() : "-";
  //         },
  //         sortable: true,
  //       }));

  //       const staticColumns = [
  //         {
  //           name: <b>User Name</b>,
  //           selector: (row) => (row.userData ? row.userData.name : "-"),
  //           sortable: true,
  //         },
  //         {
  //           name: <b>Phone Number</b>,
  //           selector: (row) => (row.userData ? row.userData.phonenumber : "-"),
  //           sortable: true,
  //         },
  //         {
  //           name: <b>Hub Name</b>,
  //           selector: (row) =>
  //             row.currentHubs && row.currentHubs.length > 0
  //               ? row.currentHubs.map((hub) => hub.name).join(", ")
  //               : "-",
  //           sortable: true,
  //         },
  //       ];

  //       setApiCol([...staticColumns, ...dynamicColumns]);
  //       setApiState(attendanceData);
  //     } else {
  //       console.error("Unexpected data format from API", apiResponse);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data: ", error);
  //   }
  // };

  const handleFilterApply = (filterFromDate, filterToDate) => {
    setIsFiltered(true);
    setFromDate(filterFromDate);
    setToDate(filterToDate);
    fetchData(filterFromDate, filterToDate);
  };

  const handleRemoveFilters = () => {
    setFromDate("");
    setToDate("");
    fetchData();
  };

  useEffect(() => {
    if (!isFiltered) {
      fetchData();
    }
  }, [isFiltered]);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      apistate.map((row) => {
        const formattedRow = {
          "User Name": row.userData ? row.userData.name : "-",
          "Phone Number": row.userData ? row.userData.phonenumber : "-",
          "Hub Name":
            row.currentHubs && row.currentHubs.length > 0
              ? row.currentHubs.map((hub) => hub.name).join(", ")
              : "-",
        };
        row.shifts.forEach((shift) => {
          const date = new Date(shift.check_in).toLocaleDateString();
          formattedRow[date] = new Date(shift.check_in).toLocaleTimeString();
        });
        return formattedRow;
      })
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Attendance");

    XLSX.writeFile(workbook, "Attendance_Data.xlsx");
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
                <button onClick={exportToExcel} className="btn btn-success">
                  Export to Excel
                </button>
              </div>
              <div className="card-body">
                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="name"
                    title_btn={true}
                    title="Attendance List"
                    apidata={apistate}
                    columns={apicol}
                    onFilterApply={handleFilterApply}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
