import React from "react";
import { Route, Routes } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Navigate, Outlet } from "react-router-dom";

import Dashboard from "../pages/dashboard/index";
// Auth
import Login from "../pages/Auth/login";
import Register from "../pages/Auth/register";
import ForgotPassword from "../pages/Auth/forgot_password";
// Profile setting
import Profile from "../pages/profileSetting/index";
// Payment
import PaymentsAllCom from "../pages/payment/index";
import PendingPayments from "../pages/payment/Pending";
import TrashPayment from "../pages/payment/Trash";
import RefundPayment from "../pages/payment/Refund";
// Bank payment
import BankPaymentsAllCom from "../pages/bankPayment/index";
import BankPendingPayments from "../pages/bankPayment/Pending";
import BankTrashPayment from "../pages/bankPayment/Trash";
import BankRefundPayment from "../pages/bankPayment/Refund";
// Stored data
import StoredDataAll from "../pages/storedData/index";
import StoredDataTrashAll from "../pages/storedData/Trash";
// Manage Invoice
import ManageInvoice from "../pages/manageInvoice/index";
import AddInvoice from "../pages/manageInvoice/add_invoice";
import InvoiceLink from "../pages/manageInvoice/invoice_link";
// Brand setting
import ManageBrand from "../pages/manageBrand/index";
import AddBrand from "../pages/manageBrand/add_brand";
import EditBrand from "../pages/manageBrand/edit_brand";
import ManageFAQ from "../pages/manageBrand/manage_faq";
import AddFAQ from "../pages/manageBrand/add_faq";
import EditFAQ from "../pages/manageBrand/edit_faq";
// User Setting
import UserList from "../pages/user/user_list";
// Payment setting
import BkashPaySetting from "../pages/paymentSetting/mobailBank/bkash";
import CellfinPaySetting from "../pages/paymentSetting/mobailBank/cellfin";
import IpayPaySetting from "../pages/paymentSetting/mobailBank/ipay";
import NagadPaySetting from "../pages/paymentSetting/mobailBank/nagad";
import OkwalletPaySetting from "../pages/paymentSetting/mobailBank/okWallet";
import RocketPaySetting from "../pages/paymentSetting/mobailBank/rocket";
import TapPaySetting from "../pages/paymentSetting/mobailBank/tap";
import UpayPaySetting from "../pages/paymentSetting/mobailBank/upay";
// Mobail bank API
import MobailBankApiMethod from "../pages/paymentSetting/mobailBank/apiMethod/index";
import AddMobailBankApi from "../pages/paymentSetting/mobailBank/apiMethod/add_api_method";
import EditMobailBankApi from "../pages/paymentSetting/mobailBank/apiMethod/edit_api_method";
// Bank Transfer
import ManageBankTransfer from "../pages/paymentSetting/bankTransfer/index";
import AddBankTransfer from "../pages/paymentSetting/bankTransfer/add_bank";
import EditBankTransfer from "../pages/paymentSetting/bankTransfer/edit_bank";
// International payment
import InternationalPayPaypal from "../pages/paymentSetting/international/paypal";
import InternationalPayPaddle from "../pages/paymentSetting/international/paddle";
import InternationalPayPaypalPersonal from "../pages/paymentSetting/international/paypal_personal";
import InternationalPayPerfectMoney from "../pages/paymentSetting/international/perfectMoney";
import InternationalPayStripe from "../pages/paymentSetting/international/stripe";
// International payment manual
import InternationalPayManual from "../pages/paymentSetting/international/manualMethod/index";
// Addons
import BulkSmsBd from "../pages/addons/sms_setting/bulkSmsBd";
import MimSms from "../pages/addons/sms_setting/mimSms";
import SmsQ from "../pages/addons/sms_setting/smsq";
import Alpha from "../pages/addons/sms_setting/alpha";
import SmsTransaction from "../pages/addons/sms_transaction/index";
// System Setting
import AppSetting from "../pages/systemSetting/appSetting/index";
import Templete from "../pages/systemSetting/templete/index";
import CustomCss from "../pages/systemSetting/customCss/index";
import Mail from "../pages/systemSetting/mail/index";
import ActiveLicense from "../pages/systemSetting/license/index";
// Notification
import OnSignal from "../pages/systemSetting/onSignal/index";
import NotifyEmail from "../pages/systemSetting/email/index";
// Role Management
import RollUser from "../pages/roleManagement/index";
import RollEdit from "../pages/roleManagement/editRoll";
import RollAccess from "../pages/roleManagement/RoleAccess";
// Theme market
import ThemeMarket from "../pages/Them_Market/index";
import ThemeLivePreview from "../pages/Them_Market/live_preview";
// Change Password
import ChangePassword from "../pages/changePassword/index";
// Activities
import Activities from "../pages/activities/index";
// General Setting
import GeneralSetting from "../pages/generalSetting/index";
// Vehicle Routes
import Vehicle from "../pages/vehicles/vehicle";
import CreateVehicle from "../pages/vehicles/create_vehicle";
import VehicleHistory from "../pages/vehicles/vehiclehistory";
import MapComponent from "../pages/vehicles/MapComponent";

// Driver Routes
import Driver from "../pages/drivers/driver";
import CreateDriver from "../pages/drivers/create_driver";
// Hub Routes
import Hub from "../pages/hubs/hub";
import CreateHub from "../pages/hubs/create_hub";

// Vendor Routes
import Vendor from "../pages/vendors/vendor";
import CreateVendor from "../pages/vendors/create_vendor";

//Client Routes
import Client from "../pages/clients/client";
import Create_client from "../pages/clients/create_client";

//User Routes
import User from "../pages/users/user";
import Create_user from "../pages/users/create_user";
import UserHistory from "../pages/users/userhistory";

import AllUsers from "../pages/allusers/allusers";

import Role from "../pages/roles/role";
import Create_role from "../pages/roles/create_role";

// Utilization Routes
import Utilization from "../pages/utilizations/utilization";
// General Staff Routes
import GeneralStaff from "../pages/generalstaffs/generalstaff";
import CreateGeneralstaffs from "../pages/generalstaffs/create_generalstaff";

import ProtectedRoute from "../components/protectedRoute";
import Layouts from "../layouts/layouts";
import { AuthProvider } from "../pages/Auth/AuthContext";
import Generalstafftype from "../pages/generalstaffstypes/genralstafftype";
import CreateGeneralstaffstype from "../pages/generalstaffstypes/create_generalstafftype";
import Leaves from "../pages/leaves/leaves";
import LeaveHistory from "../pages/leaves/leaveHistory";
import CreateHau from "../pages/hubsandusers/create_hau";
import Hau from "../pages/hubsandusers/hau";
import Defaulter from "../pages/defaulters/defaulters";
import DefaulterHistory from "../pages/defaulters/defaulterhistory";
import Backup from "../pages/backups/backup";
import Swap from "../pages/swap/swap";
import Order from "../pages/orders/order";
import Create_order from "../pages/orders/create_order";
import OrderReport from "../pages/ordersreport/orderreport";
import OrderVehicleReport from "../pages/ordersvehiclereport/ordervehicle";
import OrderUserReport from "../pages/ordersuserreport/orderuser";

import Category from "../pages/damagecategory/damagecategory";
import Create_damagecategory from "../pages/damagecategory/create_damagecategory";
import Categor from "../pages/expensecategory/expensecategory";
import Create_expensecategory from "../pages/expensecategory/create_expensecategory";
import Damage from "../pages/damages/damage";
import Create_damage from "../pages/damages/create_damage";
import ActiveInactive from "../pages/activeinactive/activeinactive";
import Expense from "../pages/expenses/expense";
import Create_expense from "../pages/expenses/create_expense";
import Advance from "../pages/advances/advance";
import Create_advance from "../pages/advances/create_advance";
import AssetType from "../pages/assets/assettype";
import Create_assettype from "../pages/assets/create_assettype";
import Asset from "../pages/asset/asset";
import Create_asset from "../pages/asset/create_asset";
import Salary from "../pages/salaries/salary";
import Create_salary from "../pages/salaries/create_salary";
import Ticket from "../pages/tickets/ticket";
import Create_ticket from "../pages/tickets/create_ticket";
import Create_type from "../pages/generalstaffstypes/create_generalstafftype";
import Type from "../pages/vehicleType/type";
import Create_Type from "../pages/vehicleType/create_type";
import Payout from "../pages/payouts/payout";
import Create_payout from "../pages/payouts/create_payout";
import AuditLog from "../pages/auditlog/auditlog";
import MovingNotmoving from "../pages/movingnotmoving/movingnotmoving";
import HubHistory from "../pages/hubs/hubhistory";
import ExpenseReport from "../pages/expensereport/expensereport";
import FakeLocation from "../pages/fakelocation/fakelocation";
import VehicleUtilization from "../pages/vehicleutilization/vehicleutilization";
import HubUtilization from "../pages/hubutilization/hubutilization";
import Compliance from "../pages/compliance/compliance";
import Create_compliance from "../pages/compliance/create_compliance";
import Lease from "../pages/lease/lease";
import Create_lease from "../pages/lease/create_lease";
import VehicleTracking from "../pages/vehicleLocation/VehicleLocation.jsx";
import VehicleVerification from "../pages/vehicleverification/vehicleverification.js";
import Create_VehicleVerification from "../pages/vehicleverification/create_vehicleverification.js";
import VehicleCondition from "../pages/vehiclecondition/vehiclecondition.js";
import Attendance from "../pages/attendanceconsolidated/attendance.js";

// const isExpired = () => {
//   if (!localStorage.getItem("token")) return true;
//   else {
//     return false;
//   }
// };
const isExpired = () => {
  const token = localStorage.getItem("token");
  if (!token) return true;

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    if (decoded.exp < currentTime) {
      // Token is expired
      localStorage.removeItem("token"); // Remove the expired token
      return true;
    }
    return false; // Token is valid
  } catch (error) {
    console.error("Error decoding token:", error);
    localStorage.removeItem("token"); // Remove invalid token
    return true;
  }
};

const AuthWrapper = () => {
  if (isExpired()) {
    return <Navigate to="/login" replace={true} />;
  } else {
    return <Outlet />;
  }
};

export function AdminRouteq() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<AuthWrapper />}>
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<Layouts />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/profileSetting" element={<Profile />} />
            <Route path="/payment" element={<PaymentsAllCom />} />
            <Route path="/pending-payment" element={<PendingPayments />} />
            <Route path="/trash-payment" element={<TrashPayment />} />
            <Route path="/refund-payment" element={<RefundPayment />} />
            <Route path="/bank-payment" element={<BankPaymentsAllCom />} />
            <Route
              path="/bank-pending-payment"
              element={<BankPendingPayments />}
            />
            <Route path="/bank-trash-payment" element={<BankTrashPayment />} />
            <Route
              path="/bank-refund-payment"
              element={<BankRefundPayment />}
            />
            <Route path="/stored-data" element={<StoredDataAll />} />
            <Route path="/stored-data-trash" element={<StoredDataTrashAll />} />
            <Route path="/manage-invoice" element={<ManageInvoice />} />
            <Route path="/add-invoice" element={<AddInvoice />} />
            <Route path="/invoice-link" element={<InvoiceLink />} />
            <Route path="/manage-brand" element={<ManageBrand />} />
            <Route path="/add-brand" element={<AddBrand />} />
            <Route path="/edit-brand/:id" element={<EditBrand />} />
            <Route path="/manageFaq" element={<ManageFAQ />} />
            <Route path="/addFaq" element={<AddFAQ />} />
            <Route path="/editFaq/:id" element={<EditFAQ />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/mobailBank/bKash" element={<BkashPaySetting />} />
            <Route path="/mobailBank/cellfin" element={<CellfinPaySetting />} />
            <Route path="/mobailBank/ipay" element={<IpayPaySetting />} />
            <Route path="/mobailBank/nagad" element={<NagadPaySetting />} />
            <Route
              path="/mobailBank/okWallet"
              element={<OkwalletPaySetting />}
            />
            <Route path="/mobailBank/rocket" element={<RocketPaySetting />} />
            <Route path="/mobailBank/tap" element={<TapPaySetting />} />
            <Route path="/mobailBank/upay" element={<UpayPaySetting />} />
            <Route path="/mobail-bank-api" element={<MobailBankApiMethod />} />
            <Route path="/add-mobailBankApi" element={<AddMobailBankApi />} />
            <Route
              path="/edit-mobailBankApi/:id"
              element={<EditMobailBankApi />}
            />
            <Route
              path="/manage-bankTransfer"
              element={<ManageBankTransfer />}
            />
            <Route
              path="/bankTransfer/add-bank"
              element={<AddBankTransfer />}
            />
            <Route
              path="/bankTransfer/edit-bank/:id"
              element={<EditBankTransfer />}
            />
            <Route
              path="/international-payment/paypal"
              element={<InternationalPayPaypal />}
            />
            <Route
              path="/international-payment/paddle"
              element={<InternationalPayPaddle />}
            />
            <Route
              path="/international-payment/paypal_personal"
              element={<InternationalPayPaypalPersonal />}
            />
            <Route
              path="/international-payment/perfectMoney"
              element={<InternationalPayPerfectMoney />}
            />
            <Route
              path="/international-payment/stripe"
              element={<InternationalPayStripe />}
            />
            <Route
              path="/manage-international-method"
              element={<InternationalPayManual />}
            />
            <Route path="/sms-setting/bulksmsbd" element={<BulkSmsBd />} />
            <Route path="/sms-setting/mimsms" element={<MimSms />} />
            <Route path="/sms-setting/smsq" element={<SmsQ />} />
            <Route path="/sms-setting/alpha" element={<Alpha />} />
            <Route path="/sms-transaction" element={<SmsTransaction />} />
            <Route
              path="/system-setting/app-setting"
              element={<AppSetting />}
            />
            <Route path="/system-setting/templete" element={<Templete />} />
            <Route path="/system-setting/custom-css" element={<CustomCss />} />
            <Route path="/system-setting/mail" element={<Mail />} />
            <Route
              path="/system-setting/active-license"
              element={<ActiveLicense />}
            />
            <Route path="/notification/onSignal" element={<OnSignal />} />
            <Route path="/notification/email" element={<NotifyEmail />} />
            <Route path="/role-user" element={<RollUser />} />
            <Route path="/editRole/:id" element={<RollEdit />} />
            <Route path="/role-access" element={<RollAccess />} />
            <Route path="/theme-market" element={<ThemeMarket />} />
            <Route path="/live-preview/:id" element={<ThemeLivePreview />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/general-setting" element={<GeneralSetting />} />
            <Route path="/vehicle" element={<Vehicle />} />
            <Route path="/create-vehicle" element={<CreateVehicle />} />
            <Route path="/vehiclehistory/:id" element={<VehicleHistory />} />
            <Route path="/userhistory/:id" element={<UserHistory />} />
            <Route path="/hubhistory/:id" element={<HubHistory />} />
            <Route
              path="/defaulterhistory/:driverId"
              element={<DefaulterHistory />}
            />
            <Route path="/backup" element={<Backup />} />
            <Route path="/swap" element={<Swap />} />
            <Route path="/mymapcomponent/:id" element={<MapComponent />} />
            <Route path="/defaulter" element={<Defaulter />} />

            {/* <Route path="/vehiclehistory" element={<VehicleHistory />} /> */}
            <Route path="/driver" element={<Driver />} />
            <Route path="/create-driver" element={<CreateDriver />} />
            <Route path="/compliance" element={<Compliance />} />
            <Route path="/create-compliance" element={<Create_compliance />} />
            <Route path="/hub" element={<Hub />} />
            <Route path="/create-hub" element={<CreateHub />} />

            <Route path="/lease" element={<Lease />} />
            <Route path="create_lease" element={<Create_lease />} />
            {/* <Route path="/type" element={<Type />} />
             */}
            <Route path="/create-type" element={<Create_type />} />

            <Route path="/ticket" element={<Ticket />} />
            <Route path="/create-ticket" element={<Create_ticket />} />

            <Route path="/salary" element={<Salary />} />
            <Route path="/create-salary" element={<Create_salary />} />
            <Route path="/payout" element={<Payout />} />
            <Route path="/create-payout" element={<Create_payout />} />
            <Route path="/vendor" element={<Vendor />} />
            <Route path="/create-vendor" element={<CreateVendor />} />
            <Route path="/client" element={<Client />} />
            <Route path="/create-client" element={<Create_client />} />
            <Route path="/auditlog" element={<AuditLog />} />
            <Route path="/fakelocation" element={<FakeLocation />} />
            <Route path="/movingnotmoving" element={<MovingNotmoving />} />
            <Route path="/order" element={<Order />} />
            <Route path="/create-order" element={<Create_order />} />
            <Route path="/expensereport" element={<ExpenseReport />} />
            <Route path="/hubutilization" element={<HubUtilization />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route
              path="/vehicleutilization"
              element={<VehicleUtilization />}
            />

            <Route path="/orderreport" element={<OrderReport />} />
            <Route
              path="/ordervehiclereport"
              element={<OrderVehicleReport />}
            />
            <Route path="/orderuserreport" element={<OrderUserReport />} />

            <Route path="/damagecategory" element={<Category />} />
            <Route
              path="/create_damagecategory"
              element={<Create_damagecategory />}
            />
            <Route path="/expensecategory" element={<Categor />} />
            <Route
              path="/create_expensecategory"
              element={<Create_expensecategory />}
            />
            <Route path="/assettype" element={<AssetType />} />
            <Route path="/create_assettype" element={<Create_assettype />} />

            <Route path="expense" element={<Expense />} />
            <Route path="create_expense" element={<Create_expense />} />

            <Route path="asset" element={<Asset />} />
            <Route path="create_asset" element={<Create_asset />} />

            <Route path="damage" element={<Damage />} />
            <Route path="create_damage" element={<Create_damage />} />

            <Route path="advance" element={<Advance />} />
            <Route path="create_advance" element={<Create_advance />} />

            <Route path="/user" element={<User />} />
            {/* <Route element={<AuthWrapper />}>
              <Route path="/user" element={<User />} />
            </Route> */}

            <Route path="/allusers" element={<AllUsers />} />
            <Route path="/create-user" element={<Create_user />} />
            <Route path="/role" element={<Role />} />
            <Route path="/create-role" element={<Create_role />} />
            <Route path="/hau" element={<Hau />} />

            <Route path="/create-hau" element={<CreateHau />} />

            <Route path="/utilization" element={<Utilization />} />
            <Route path="/generalstaff" element={<GeneralStaff />} />
            <Route
              path="/create-generalstaffs"
              element={<CreateGeneralstaffs />}
            />
            <Route path="/generalstafftype" element={<Generalstafftype />} />

            <Route
              path="/create-generalstafftype"
              element={<CreateGeneralstaffstype />}
            />
            <Route
              path="/create-generalstaffstype"
              element={<CreateGeneralstaffstype />}
            />

            {/* <Route path="/vehicletype" element={<VehicleType />} /> */}
            <Route path="/activeinactive" element={<ActiveInactive />} />
            <Route path="/type" element={<Type />} />
            <Route path="/vehiclecondition" element={<VehicleCondition />} />
            <Route
              path="/vehicleverification"
              element={<VehicleVerification />}
            />
            <Route
              path="/create_vehicleverification"
              element={<Create_VehicleVerification />}
            />
            <Route path="/create_type" element={<Create_Type />} />
            <Route path="/leave" element={<Leaves />} />
            <Route path="/leavehistory/:id" element={<LeaveHistory />} />
            {/* Vehicle Tracking  */}
            <Route path="/vehicle-location" element={<VehicleTracking />} />
          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  );
}
