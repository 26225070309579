import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import FilterModal from "../Filter Modal/FilterModal";

export default function DataTable_Component({
  apidata,
  columns,
  searchBy,
  setOverallLeasing,
}) {
  const [dataFilter, setDataFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [onboardingFilter, setOnboardingFilter] = useState("");
  const [leasingFilter, setLeasingFilter] = useState("");
  useEffect(() => {
    let filtered = apidata;

    // Apply Search Filter
    if (dataFilter) {
      filtered = filtered.filter((item) => {
        if (searchBy === "numberplate") {
          return (
            item.id_vehicle &&
            item.id_vehicle.some(
              (vehicle) =>
                vehicle.numberplate &&
                vehicle.numberplate
                  .toLowerCase()
                  .includes(dataFilter.toLowerCase())
            )
          );
        }
        return false;
      });
    }
    if (leasingFilter) {
      filtered = filtered.filter((item) =>
        item.leasing_partner.toLowerCase().includes(leasingFilter.toLowerCase())
      );
    }

    setFilteredData(filtered);
    const totalLeasingSum = filtered.reduce((sum, item) => {
      const vehicles =
        (Array.isArray(item.id_vehicle) &&
          item.id_vehicle.map((v) => v.numberplate)) ||
        [];
      return sum + vehicles.length * (item.leasing_amount || 0);
    }, 0);

    setOverallLeasing(totalLeasingSum); // Update salary in parent component

    // Apply Status Filter
    if (statusFilter) {
      filtered = filtered.filter(
        (item) =>
          item.status &&
          item.status.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    // Apply Onboarding Date Filter
    if (onboardingFilter) {
      const filterDate = new Date(onboardingFilter); // Convert to Date object
      filtered = filtered.filter((item) => {
        // Compare the date part only
        return (
          item.onboardingDate &&
          new Date(item.onboardingDate).toDateString() ===
            filterDate.toDateString()
        );
      });
    }

    // Apply From Date and To Date Filters
    setFilteredData(filtered);
  }, [
    dataFilter,
    apidata,
    searchBy,
    onboardingFilter,
    leasingFilter,
    statusFilter,
  ]);

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const removeFilters = () => {
    setShowFilterModal(false);
    setLeasingFilter("");
    setStatusFilter("");
    setOnboardingFilter("");
  };

  return (
    <div className="table-responsive">
      <div className="row justify-content-between mb-2">
        <div className="col-md-3 mb-2">
          <input
            type="text"
            placeholder={`Search by ${searchBy}`}
            className="form-control"
            value={dataFilter}
            onChange={(e) => setDataFilter(e.target.value)}
          />
        </div>
        <div className="col-md-9 mb-2">
          <button
            className="btn btn-primary"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>
        </div>
      </div>
      {filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          highlightOnHover
          responsive
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      ) : (
        <div className="row justify-content-start">
          <div className="col-12">
            <p>No results found.</p>
          </div>
        </div>
      )}
      <FilterModal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        applyFilters={applyFilters}
        removeFilters={removeFilters}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        onboardingFilter={onboardingFilter}
        setOnboardingFilter={setOnboardingFilter}
        apidata={apidata}
        leasingFilter={leasingFilter}
        setLeasingFilter={setLeasingFilter}
      />
    </div>
  );
}
