import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateDefect,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      setUpdate_modal_data((prevData) => ({
        ...prevData,
      }));
    }
  }, [select_data]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUpdate_modal_data({ ...update_modal_data, [name]: value });
  };

  const validateFields = () => {
    const newErrors = {};
    let isValid = true;
    if (!update_modal_data.defectName) {
      errors.defectName = "Defect Name is required";
      isValid = false;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    if (!validateFields()) {
      Swal.fire("Please fill in all required fields", "", "error");
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to take this action?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { _id } = update_modal_data;
          if (!_id) {
            throw new Error("ID is not defined");
          }

          const formData = new FormData();
          formData.append("defectName", update_modal_data.defectName);
          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/v3/vehicle-defect/${_id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (res.data.success) {
            Swal.fire("Update success", "", "success");
            onUpdateDefect(update_modal_data);
            setRen(!ren);
          }
        } catch (error) {
          Swal.fire("Update failed", "", "error");
          console.error("Error updating type:", error);
        }
        set_update_data_modal_Show(false);
      }
    });
  };

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Defect Type Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> Vehicle Defect *</Form.Label>
              <Form.Control
                type="text"
                name="defectName"
                value={update_modal_data.defectName || ""}
                onChange={handleChange}
                placeholder="Enter Defect Name"
              />
              {errors.defectName && (
                <p className="text-danger">{errors.defectName}</p>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update Type
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
