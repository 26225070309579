import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";

export default function Add_data_modal({
  select_data,
  open_update_data_modal,
  onUpdateClient,
}) {
  const [ren, setRen] = useState(false);
  const [update_modal_data, setUpdate_modal_data] = useState(select_data);
  const [add_data_modal_Show, set_update_data_modal_Show] = useState(false);
  const [hubs, setHubs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (select_data) {
      setUpdate_modal_data(select_data);
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_hub: select_data.id_hub._id,
      }));
    }
  }, [select_data, hubs]);

  useEffect(() => {
    set_update_data_modal_Show(open_update_data_modal.modal_open);
  }, [open_update_data_modal]);

  const handleClose = () => {
    set_update_data_modal_Show(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "id_hub") {
      setUpdate_modal_data((prevData) => ({
        ...prevData,
        id_hub: { ...prevData.id_hub, [name]: value },
      }));
    } else if (name === "clientImage") {
      setUpdate_modal_data({ ...update_modal_data, [name]: files });
    } else {
      setUpdate_modal_data({ ...update_modal_data, [name]: value });
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!update_modal_data.name) newErrors.name = "Client name is required";
    if (!update_modal_data.payout) newErrors.payout = "Payout is required";
    if (!update_modal_data.payoutperpacket)
      newErrors.payoutperpacket = "Payout per packet is required";
    if (!update_modal_data.id_hub)
      newErrors.id_hub = "Hub assignment is required";
    if (!update_modal_data.status) newErrors.status = "Status is required";
    if (!update_modal_data.clientImage)
      newErrors.clientImage = "Client Image is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    if (!validateFields()) {
      Swal.fire("Please fill in all required fields", "", "error");
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to take this action?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { _id } = update_modal_data;
          if (!_id) {
            throw new Error("ID is not defined");
          }

          // Create a new FormData object
          const formData = new FormData();
          formData.append("name", update_modal_data.name);
          formData.append("payout", update_modal_data.payout);
          formData.append("payoutperpacket", update_modal_data.payoutperpacket);
          formData.append("id_hub", update_modal_data.id_hub);
          formData.append("status", update_modal_data.status);
          formData.append("onBoardingDate", update_modal_data.onBoardingDate);

          // Append the file if it exists
          if (
            update_modal_data.clientImage &&
            update_modal_data.clientImage[0]
          ) {
            formData.append("clientImage", update_modal_data.clientImage[0]);
          }

          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/v1/client/${_id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
              },
            }
          );

          if (res.data.success) {
            Swal.fire("Update success", "", "success");
            onUpdateClient(update_modal_data);
            setRen(!ren);
          }
        } catch (error) {
          Swal.fire("Update failed", "", "error");
          console.error("Error updating client:", error);
        }
        set_update_data_modal_Show(false);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  useEffect(() => {
    const fetchHubs = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/hubs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setHubs(response.data.hubs);
      } catch (error) {
        console.error("Error fetching hub:", error);
        setHubs([]);
      }
    };
    fetchHubs(currentPage);
  }, [currentPage]);

  const handleHubChange = (e) => {
    const { value } = e.target;
    setUpdate_modal_data((prevData) => ({
      ...prevData,
      id_hub: value,
    }));
  };

  return (
    <>
      <Modal show={add_data_modal_Show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Client Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Client Name *</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={update_modal_data.name || ""}
                onChange={handleChange}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Payouts *</Form.Label>
              <Form.Control
                type="number"
                name="payout"
                value={update_modal_data.payout || ""}
                onChange={handleChange}
                isInvalid={!!errors.payout}
              />
              <Form.Control.Feedback type="invalid">
                {errors.payout}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Payout Per Packet *</Form.Label>
              <Form.Control
                type="number"
                name="payoutperpacket"
                value={update_modal_data.payoutperpacket || ""}
                onChange={handleChange}
                isInvalid={!!errors.payoutperpacket}
              />
              <Form.Control.Feedback type="invalid">
                {errors.payoutperpacket}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Assign Hub *</Form.Label>
              <Form.Control
                as="select"
                name="id_hub"
                value={update_modal_data.id_hub || ""}
                onChange={handleHubChange}
                isInvalid={!!errors.id_hub}
              >
                <option value="">Select Hub</option>
                {hubs.map((hub) => (
                  <option key={hub._id} value={hub._id}>
                    {hub.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.id_hub}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Status *</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={update_modal_data.status || ""}
                onChange={handleChange}
                isInvalid={!!errors.status}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>OnBoard Date *</Form.Label>
              <Form.Control
                type="date"
                name="onBoardingDate"
                value={update_modal_data.onBoardingDate || ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Client Image *</Form.Label>
              <Form.Control
                type="file"
                name="clientImage"
                accept="image/*"
                onChange={handleChange}
              />
              {errors.clientImage && (
                <small className="text-danger">{errors.clientImage}</small>
              )}
            </Form.Group>

            {/* Image Preview */}
            {(update_modal_data.clientImage &&
              update_modal_data.clientImage[0]) ||
            select_data.clientImage?.url ? (
              <Form.Group className="mb-3">
                <Form.Label>Image Preview</Form.Label>
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      update_modal_data.clientImage &&
                      update_modal_data.clientImage[0] instanceof File
                        ? URL.createObjectURL(update_modal_data.clientImage[0])
                        : select_data.clientImage?.url
                    }
                    alt="Client Image"
                    style={{ maxWidth: "50%", height: "30%" }}
                    onLoad={() => {
                      if (
                        update_modal_data.clientImage &&
                        update_modal_data.clientImage[0] instanceof File
                      ) {
                        URL.revokeObjectURL(
                          URL.createObjectURL(update_modal_data.clientImage[0])
                        );
                      }
                    }}
                  />
                </div>
              </Form.Group>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-sm"
            onClick={handleUpdate}
          >
            Update Client
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
