import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import FilterModal from "../Filter Modal/FilterModal";

export default function DataTable_Component({ apidata, columns, searchBy }) {
  const [dataFilter, setDataFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [clientFilter, setClientFilter] = useState("");

  useEffect(() => {
    let filtered = apidata;

    // Search by numberplate
    if (dataFilter) {
      filtered = filtered.filter((item) => {
        if (searchBy === "numberplate") {
          return (
            item.id_vehicle &&
            item.id_vehicle.numberplate &&
            item.id_vehicle.numberplate
              .toLowerCase()
              .includes(dataFilter.toLowerCase())
          );
        }
        return false;
      });
    }
    if (clientFilter) {
      filtered = filtered.filter(
        (item) =>
          item.id_client &&
          item.id_client.name &&
          item.id_client.name.toLowerCase().includes(clientFilter.toLowerCase())
      );
    }

    // Filter by Created Date
    if (fromDate) {
      filtered = filtered.filter((item) => {
        const createdDate = new Date(item.createdAt)
          .toISOString()
          .split("T")[0];
        return createdDate >= fromDate;
      });
    }

    setFilteredData(filtered);
  }, [dataFilter, fromDate, clientFilter, apidata, searchBy]);

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const removeFilters = () => {
    setShowFilterModal(false);
    setClientFilter("");
    setFromDate("");
  };

  return (
    <div className="table-responsive">
      <div className="row justify-content-between mb-2">
        <div className="col-md-3 mb-2">
          <input
            type="text"
            placeholder={`Search by ${searchBy}`}
            className="form-control"
            value={dataFilter}
            onChange={(e) => setDataFilter(e.target.value)}
          />
        </div>
        <div className="col-md-9 mb-2">
          <button
            className="btn btn-primary"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>
        </div>
      </div>
      {filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          highlightOnHover
          responsive
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      ) : (
        <div className="row justify-content-start">
          <div className="col-12">
            <p>No results found.</p>
          </div>
        </div>
      )}
      <FilterModal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        applyFilters={applyFilters}
        removeFilters={removeFilters}
        fromDate={fromDate}
        setFromDate={setFromDate} // Pass setFromDate
        apidata={apidata}
        clientFilter={clientFilter}
        setClientFilter={setClientFilter}
      />
    </div>
  );
}
