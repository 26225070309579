import { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/ordersreport/Data Table Component/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2"; // Import the Bar chart
import "chart.js/auto"; // Import Chart.js
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import Layouts from "../../layouts/layouts";
import Add_data_modal from "../../components/hubs/update_data_modal";
import HubDetailModal from "../../components/hubs/Hub Details";
import { Tooltip } from "react-tooltip";

export default function ExpenseReport() {
  const title = "Outlay Report";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isFiltered, setIsFiltered] = useState(false); // Track if filters are applied

  const [chartData, setChartData] = useState({});

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showHubDetailModal, setShowHubDetailModal] = useState(false);
  const [selectedHub, setSelectedHub] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const columns = [
    {
      name: <b>Hub Name</b>,
      selector: (row) => row.hubName,
      sortable: true,
    },
    {
      name: <b>Debited Amount</b>,
      selector: (row) => row.debitAmount,
      sortable: true,
    },
    {
      name: <b>Revenue</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory.Revenue &&
        row.expensesByCategory.Revenue.summary
          ? row.expensesByCategory.Revenue.summary.debitAmount
          : "-",
      sortable: true,
    },

    {
      name: <b>On Demand Rides</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["On-Demand Rides"] &&
        row.expensesByCategory["On-Demand Rides"].summary
          ? row.expensesByCategory["On-Demand Rides"].summary.debitAmount
          : "-",
      sortable: true,
    },
    {
      name: <b>Salary</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory.Salary &&
        row.expensesByCategory.Salary.summary
          ? row.expensesByCategory.Salary.summary.debitAmount
          : "-",
      sortable: true,
    },

    {
      name: <b>Vehicle Lease</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["Vehicle Lease"] &&
        row.expensesByCategory["Vehicle Lease"].summary
          ? row.expensesByCategory["Vehicle Lease"].summary.debitAmount
          : "-",
      sortable: true,
    },

    {
      name: <b>EV Recharge</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["EV Recharge"] &&
        row.expensesByCategory["EV Recharge"].summary
          ? row.expensesByCategory["EV Recharge"].summary.debitAmount
          : "-",
      sortable: true,
    },

    {
      name: <b>Mobile Recharge</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["Mobile Recharge"] &&
        row.expensesByCategory["Mobile Recharge"].summary
          ? row.expensesByCategory["Mobile Recharge"].summary.debitAmount
          : "-",
      sortable: true,
    },

    {
      name: <b>Security Deposits</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["Security Deposits"] &&
        row.expensesByCategory["Security Deposits"].summary
          ? row.expensesByCategory["Security Deposits"].summary.debitAmount
          : "-",
      sortable: true,
    },

    {
      name: <b>Electricity Bill</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["Electricity Bill"] &&
        row.expensesByCategory["Electricity Bill"].summary
          ? row.expensesByCategory["Electricity Bill"].summary.debitAmount
          : "-",
      sortable: true,
    },

    {
      name: <b>IDFY Verification Fees</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["IDFY Verification Fees"] &&
        row.expensesByCategory["IDFY Verification Fees"].summary
          ? row.expensesByCategory["IDFY Verification Fees"].summary.debitAmount
          : "-",
      sortable: true,
    },
    {
      name: <b>Job Posting (Ads)</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["Job Posting (Ads)"] &&
        row.expensesByCategory["Job Posting (Ads)"].summary
          ? row.expensesByCategory["Job Posting (Ads)"].summary.debitAmount
          : "-",
      sortable: true,
    },
    {
      name: <b>Fuel/CNG</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["Fuel/CNG"] &&
        row.expensesByCategory["Fuel/CNG"].summary
          ? row.expensesByCategory["Fuel/CNG"].summary.debitAmount
          : "-",
      sortable: true,
    },
    {
      name: <b>Cabs / Rides Expense</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["Cabs / Rides Expense"] &&
        row.expensesByCategory["Cabs / Rides Expense"].summary
          ? row.expensesByCategory["Cabs / Rides Expense"].summary.debitAmount
          : "-",
      sortable: true,
    },

    {
      name: <b>Numberplate Installation</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["Numberplate Installation"] &&
        row.expensesByCategory["Numberplate Installation"].summary
          ? row.expensesByCategory["Numberplate Installation"].summary
              .debitAmount
          : "-",
      sortable: true,
    },
    {
      name: <b>Police Expense</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["Police Expense"] &&
        row.expensesByCategory["Police Expense"].summary
          ? row.expensesByCategory["Police Expense"].summary.debitAmount
          : "-",
      sortable: true,
    },
    {
      name: <b>Vehicle Challan</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["Vehicle Challan"] &&
        row.expensesByCategory["Vehicle Challan"].summary
          ? row.expensesByCategory["Vehicle Challan"].summary.debitAmount
          : "-",
      sortable: true,
    },
    {
      name: <b>Tax</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory.Tax &&
        row.expensesByCategory.Tax.summary
          ? row.expensesByCategory.Tax.summary.debitAmount
          : "-",
      sortable: true,
    },
    {
      name: <b>Adhoc Data</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory.Adhoc &&
        row.expensesByCategory.Adhoc.summary
          ? row.expensesByCategory.Adhoc.summary.debitAmount
          : "-",
      sortable: true,
    },
    {
      name: <b>FASTag</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory.FASTag &&
        row.expensesByCategory.FASTag.summary
          ? row.expensesByCategory.FASTag.summary.debitAmount
          : "-",
      sortable: true,
    },
    {
      name: <b>Toll / Parking</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["Toll/Parking"] &&
        row.expensesByCategory["Toll/Parking"].summary
          ? row.expensesByCategory["Toll/Parking"].summary.debitAmount
          : "-",
      sortable: true,
    },
    {
      name: <b>No Entry</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["No Entry"] &&
        row.expensesByCategory["No Entry"].summary
          ? row.expensesByCategory["No Entry"].summary.debitAmount
          : "-",
      sortable: true,
    },
    {
      name: <b>Pantry</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory.Pantry &&
        row.expensesByCategory.Pantry.summary
          ? row.expensesByCategory.Pantry.summary.debitAmount
          : "-",
      sortable: true,
    },
    {
      name: <b>Snacks / Treats</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory["Snacks / Treats"] &&
        row.expensesByCategory["Snacks / Treats"].summary
          ? row.expensesByCategory["Snacks / Treats"].summary.debitAmount
          : "-",
      sortable: true,
    },

    {
      name: <b>Miscellaneous</b>,
      selector: (row) =>
        row.expensesByCategory &&
        row.expensesByCategory.Miscellaneous &&
        row.expensesByCategory.Miscellaneous.summary
          ? row.expensesByCategory.Miscellaneous.summary.debitAmount
          : "-",
      sortable: true,
    },
  ];

  const fetchData = async (filterFromDate = "", filterToDate = "") => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      let url = `${process.env.REACT_APP_BASE_URL}/api/v1/expenses/hubByDate`;
      const params = {};

      if (filterFromDate && filterToDate) {
        // Use the correct query parameter keys as expected by the API
        params.fromDate = filterFromDate;
        params.toDate = filterToDate;
      } else {
        // Default: fetch data for the previous month
        const today = new Date();
        const firstDayOfPrevMonth = moment(today)
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        const lastDayOfPrevMonth = moment(today)
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        params.fromDate = firstDayOfPrevMonth;
        params.toDate = lastDayOfPrevMonth;
      }

      const response = await axios.get(url, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (Array.isArray(response.data.data)) {
        setApiState(response.data.data);
      } else {
        console.error("Unexpected data format from API", response.data.data);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleFilterApply = (filterFromDate, filterToDate) => {
    setIsFiltered(true); // Mark that filters are applied
    setFromDate(filterFromDate);
    setToDate(filterToDate);
    fetchData(filterFromDate, filterToDate); // Fetch filtered data
  };
  const handleRemoveFilters = () => {
    setFromDate(""); // Clear from date
    setToDate(""); // Clear to date
    fetchData(); // Fetch data with default date range
  };

  useEffect(() => {
    if (!isFiltered) {
      fetchData();
    }
  }, [isFiltered]);

  const prepareChartData = (data) => {
    if (!data || !Array.isArray(data)) {
      console.error("Invalid data for chart:", data);
      return;
    }

    // Extract hub names and debited amounts
    const hubs = data.map((hub) => hub.hubName || "-"); // Handle missing hub names
    const debitedAmounts = data.map(
      (hub) => (hub.debitAmount !== undefined ? hub.debitAmount : 0) // Handle missing debitAmount
    );

    // Define colors for the chart
    const colors = [
      "rgba(255, 99, 132, 0.6)", // Red
      "rgba(54, 162, 235, 0.6)", // Blue
      "rgba(255, 206, 86, 0.6)", // Yellow
      "rgba(75, 192, 192, 0.6)", // Green
      "rgba(153, 102, 255, 0.6)", // Purple
      "rgba(255, 159, 64, 0.6)", // Orange
    ];

    // Ensure unique colors for each hub
    const dynamicColors = hubs.map((_, index) => colors[index % colors.length]);

    // Set up chart data
    setChartData({
      labels: hubs,
      datasets: [
        {
          label: "Debited Amount",
          data: debitedAmounts,
          backgroundColor: dynamicColors, // Assign colors to bars
          borderColor: dynamicColors.map((color) => color.replace("0.6", "1")), // Solid border
          borderWidth: 1,
        },
      ],
    });
  };

  useEffect(() => {
    prepareChartData(apistate);
  }, [apistate]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
      />
      <HubDetailModal
        show={showHubDetailModal}
        onHide={() => setShowHubDetailModal(false)}
        hub={selectedHub}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
              </div>
              <div className="card-body">
                <div className="mb-4" style={{ height: "300px" }}>
                  {chartData.labels ? (
                    <Bar
                      data={chartData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          legend: { display: true },
                          title: { display: true, text: "Total Trips per Hub" },
                        },
                      }}
                      height={250}
                    />
                  ) : (
                    <p>Loading chart...</p>
                  )}
                </div>

                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="hubName"
                    title_btn={true}
                    title="Vehicle List"
                    apidata={apistate}
                    columns={apicol}
                    onFilterApply={handleFilterApply}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
