import { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/hubutilization/Data Table Component/index";
import Status_modal from "../../components/manageInvoice/status_modal/index";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2"; // Import the Bar chart
import "chart.js/auto"; // Import Chart.js
import moment from "moment";
import { Badge } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap"; // Ensure you're using react-bootstrap for tooltips

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import Layouts from "../../layouts/layouts";
import Add_data_modal from "../../components/hubs/update_data_modal";
import { Tooltip } from "react-tooltip";
import { Line } from "react-chartjs-2";

import { Chart as ChartJS, registerables } from "chart.js"; // Import Chart.js Core
ChartJS.register(...registerables); // Register chart types and controllers

export default function HubUtilization() {
  const title = "   Hub Utilization Report";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isFiltered, setIsFiltered] = useState(false); // Track if filters are applied

  //   const [chartData, setChartData] = useState({});

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });

  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [showHubDetailModal, setShowHubDetailModal] = useState(false);
  const [selectedHub, setSelectedHub] = useState(null);

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const columns = [
    {
      name: <b>Hub Name</b>,
      selector: (row) => row.hubName,
      sortable: true,
    },
    {
      name: <b>Utilization Percentage %</b>,
      selector: (row) => {
        let utilization = Math.round(row.utilization);

        // Cap utilization at 100%
        if (utilization > 100) utilization = 100;

        return isNaN(utilization) ? "0%" : `${utilization}%`;
      },
      sortable: true,
    },

    {
      name: <b>Average Assigned Vehicles</b>,
      selector: (row) =>
        row.averageVehicleCount
          ? `${Math.round(row.averageVehicleCount)}`
          : "-",
      sortable: true,
    },
    {
      name: <b>Average Moving Vehicles</b>,
      selector: (row) =>
        row.averageMovingVehicleCount
          ? `${Math.round(row.averageMovingVehicleCount)}`
          : "-",
      sortable: true,
    },

    {
      name: <b>Total Days</b>,
      selector: (row) => row.totalDays,
      sortable: true,
    },

    // {
    //   name: <b>Hub Status</b>,
    //   selector: (row) => row.hubStatus,
    //   sortable: true,
    // },
    {
      name: <b>Hub Status</b>,
      cell: (row) => {
        const status = row.hubStatus ? row.hubStatus.toLowerCase() : "";
        return (
          <button
            className={`btn p-0 px-1 ${
              status === "active"
                ? "btn-success"
                : status === "inactive"
                ? "btn-danger"
                : "btn-secondary"
            }`}
          >
            {row.hubStatus || "N/A"}
          </button>
        );
      },
      sortable: true,
    },
  ];

  const fetchData = async (filterFromDate = "", filterToDate = "") => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      let url = `${process.env.REACT_APP_BASE_URL}/api/v3/hub-utilization`;
      const params = {};

      if (filterFromDate && filterToDate) {
        // Use the correct query parameter keys as expected by the API
        params.fromDate = filterFromDate;
        params.toDate = filterToDate;
      } else {
        // Default: fetch data for the previous month
        const today = new Date();
        const firstDayOfPrevMonth = moment(today)
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        const lastDayOfPrevMonth = moment(today)
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        params.fromDate = firstDayOfPrevMonth;
        params.toDate = lastDayOfPrevMonth;
      }

      const response = await axios.get(url, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (Array.isArray(response.data.hubs)) {
        setApiState(response.data.hubs);
      } else {
        console.error("Unexpected data format from API", response.data.hubs);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleFilterApply = (filterFromDate, filterToDate) => {
    setIsFiltered(true); // Mark that filters are applied
    setFromDate(filterFromDate);
    setToDate(filterToDate);
    fetchData(filterFromDate, filterToDate); // Fetch filtered data
  };
  const handleRemoveFilters = () => {
    setFromDate(""); // Clear from date
    setToDate(""); // Clear to date
    fetchData(); // Fetch data with default date range
  };

  useEffect(() => {
    if (!isFiltered) {
      fetchData();
    }
  }, [isFiltered]);

  const chartData = {
    labels: apistate.map((hubs) => hubs.hubName),
    datasets: [
      {
        label: "Utilization per Hub",
        data: apistate.map((hubs) => Math.round(hubs.utilization)), // Round off the utilization values
        fill: false, // Line without fill under it
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        backgroundColor: "rgba(75, 192, 192, 0.4)", // Point background color
        tension: 0.4, // Smoothness of the line curve
        pointBackgroundColor: "rgba(75, 192, 192, 1)", // Color for data points
        pointBorderColor: "#fff",
        pointRadius: 5, // Size of the points on the line
      },
    ],
  };

  //   useEffect(() => {
  //     chartData(apistate);
  //   }, [apistate]);

  const setStoreBtn = () => {
    setOpen_add_modal({ render: !open_add_modal.render, modal_open: true });
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  return (
    <>
      <Layouts />
      <Status_modal row={selectVal} openModal={openModal} />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
      />

      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between border-bottom pb-1">
                <h4>{title}</h4>
              </div>
              <div className="card-body">
                <div className="mb-4" style={{ height: "300px" }}>
                  {chartData.labels ? (
                    <Bar
                      data={chartData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          legend: { display: true },
                          title: {
                            display: true,
                            text: "Utilization per Hub",
                          },
                        },
                      }}
                      height={250}
                    />
                  ) : (
                    <p>Loading chart...</p>
                  )}
                </div>

                {apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="hubName"
                    title_btn={true}
                    title="Vehicle List"
                    apidata={apistate}
                    columns={apicol}
                    onFilterApply={handleFilterApply}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
